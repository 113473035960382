import { TableCell } from '@mui/material';
let CryptoJS = require("crypto-js");

export const getAccessToken = () => sessionStorage.getItem("token");
export const isAuthenticated = () => !!getAccessToken();

export const userDetails = () => {
    try {
        const secretKey = process.env.REACT_APP_CALL_BREAK_TOKEN_KEY;
        const encryptedUserDetails = sessionStorage.getItem("userDetails");

        if (encryptedUserDetails) {
            const decryptedData = CryptoJS.AES.decrypt(encryptedUserDetails, secretKey).toString(CryptoJS.enc.Utf8);
            return JSON.parse(decryptedData);
        }
    } catch (error) {
        console.error("Error decrypting user details:", error);
    }

    // Return default user details in case of decryption failure or missing data
    return { role: "", email: "" };
};
  
export const renderSrNo = (row, i, pagination) => {
  return <TableCell>{+pagination.rowsPerPage * ((+pagination.page + 1) - 1) + i + 1}</TableCell>;
};